<template>
  <div class="top-bar">
    <div class="top-left">
      <img src="../assets/logo.png" width="32px" />
      <div class="name">安司源id解析服务器</div>
    </div>
    <div class="top-right">
      <div class="phone"> 电话咨询 400-823-0608 </div>
      <div class="buy-button"><a href="https://b.akey.me/mall/mallSelfDeploy" target="_blank" style="color:#4EA2FC"> 购买 </a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar'
}
</script>

<style lang="less" scoped>
.top-bar {
  height: 4rem;
  padding: 0 20vw;
  background: #4ea2fc;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.name {
  margin-left: 0.71rem;
  height: 1.79rem;
  font-size: 1.29rem;
  font-weight: 400;
  line-height: 1.79rem;
}
.top-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.phone {
  width: 11.36rem;
  height: 1.43rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.43rem;
  margin-right: 2.14rem;
}
.buy-button {
  cursor: pointer;
  width: 5.71rem;
  height: 2.33rem;
  background: #FFFFFF;
  border-radius: 0.29rem;
  line-height: 2.33rem;
  font-size: 1rem;
  color: #4EA2FC;
}
a {
  text-decoration:none;
}
</style>
