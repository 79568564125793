<template>
  <div class="index">
    <div class="header">
        <TopBar />
      </div>
    <div class="banner">
      <div class="download-area">
        <div class="download-des">密信盒子，看得见的安全</div>
        <div class="download-name">使用 BoxTalk App 加入盒子</div>
        <div class="download tooltip">
          下载
          <span class="tooltiptext">
            <img src="../assets/download_qrcode.png" style="width:12rem" />
            <div>手机端扫描二维码</div>
            <div style="margin-top:-1rem">下载 BoxTalk App</div>
          </span>
        </div>
      </div>
    </div>
    <div class="problem-area">
      <div class="problems">
        <div class="problem-item problem-item1">
          <div class="problem-text" style="color: #E02020;">
            担心公司重要文件泄漏？
          </div>
        </div>
        <div class="problem-item problem-item2">
          <div class="problem-text" style="color: #F7B500;">
            内部机密聊天被截图外传？
          </div>
        </div>
        <div class="problem-item problem-item3">
          <div class="problem-text" style="width: 13.36rem;color: #FA6908;">
            小圈子悄悄话，担心被窃听？
          </div>
        </div>
      </div>
      <img src="../assets/line.png" />
      <div class="line-text">一个盒子开启您的专属加密聊天</div>
      <div>
        <img src="../assets/box.png" style="margin-bottom:3rem" />
      </div>
    </div>
    <div class="product-area" style="background: #24284308;">
      <div class="product-title">产品介绍</div>
      <div class="video">
        <video :src="videoUrl" :poster="videoImgUrl" type="video/mp4" controls style="height: 38.14rem;"/>
      </div>
    </div>
    <div class="product-area">
      <div class="product-title">产品特点</div>
      <div class="product-main">
        <img src="../assets/inset1.png" style="margin: 0rem 5rem;"/>
        <div class="product-text">
          <div class="title-area">看的见的安全</div>
          <div class="des-area">聊天消息只经过密信盒子，中间不解密。数据加密存储在您的盒子里，谁也拿不走。加密技术由国家密码局权威认证</div>
        </div>
      </div>
      <div class="product-main">
        <div class="product-text">
          <div class="title-area">即插即用，非常方便</div>
          <div class="des-area">连上电源，插上网线，立即拥有自己的加密聊天服务器</div>
        </div>
        <img src="../assets/inset2.png" style="margin: 0rem 5rem;" />
      </div>
      <div class="product-main">
        <img src="../assets/inset3.png" style="margin: 0rem 5rem;" />
        <div class="product-text">
          <div class="title-area">极致轻薄，可随身携带，随时使用</div>
          <div class="des-area">随身携带如何做到可靠聊天？官方提供 BoxNet 内网穿透辅助网络，不用固定IP，随时随地，只要盒子联网就能开始聊天</div>
        </div>
      </div>
    </div>
    <div class="protect">
      <div class="product-text">
        <div class="title-area">信息高保护</div>
        <div style="margin:0.71rem 0rem">
          <div class="protect-des1">远程销毁</div>
          <div class="protect-des2">发出去的消息随时可以销毁，没有限制，覆水不再难收</div>
        </div>
        <div style="margin:0.71rem 0rem">
          <div class="protect-des1">绝密会话</div>
          <div class="protect-des2">一次性聊天，边聊边删，不留痕迹</div>
        </div>
        <div style="margin:0.71rem 0rem">
          <div class="protect-des1">No!Shot特效</div>
          <div class="protect-des2">动效打码，有效防止消息被完整截屏</div>
        </div>
      </div>
      <img src="../assets/protect.png" style="margin:6rem 0rem"/>
    </div>
    <div class="buy-area">
      <img src="../assets/buy.png" style="margin-right:6.79rem"/>
      <div class="buy-text">
        <div>
          <div class="price">¥2599</div>
          <div class="base">密信盒子基础版</div>
          <div class="license">赠送10人永久授权</div>
        </div>
        <div class="buy-info">
          <div class="buy-info-title">规格：</div>
          <div class="buy-info-text">
            <div>4核CPU</div>
            <div class="divide"></div>
            <div>8G内存</div>
            <div class="divide"></div>
            <div>256G SSD硬盘</div>
          </div>
        </div>
        <div class="buy-info">
          <div class="buy-info-title">其他授权人数</div>
          <div class="buy-info-text" style="justify-content: space-between;">
            <div>25人永久授权</div>
            <div>另加 ¥18000</div>
          </div>
          <div class="buy-info-text" style="justify-content: space-between;">
            <div>50人永久授权</div>
            <div>另加 ¥35000</div>
          </div>
        </div>
        <div>
          <a class="buy-button" href="https://b.akey.me/mall/mallSelfDeploy" target="_blank" style="color:white">
            <div class="buy-inner"> 购买 </div>
            <img src="../assets/to.png" style="width:3.29rem" />
          </a>
        </div>
      </div>
    </div>
    <div>
      <FooterBar />
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/TopBar.vue'
import FooterBar from '@/components/FooterBar.vue'
import { videoUrl, videoImgUrl } from '@/common/constant.js'

export default {
  name: 'Index',
  components: {
    TopBar,
    FooterBar
  },
  data () {
    return {
      videoUrl,
      videoImgUrl
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  background: url(../assets/bg.png);
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 30vw;
  color: white;
}
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.download-area {
  padding-top: 9vw;
  padding-left: 20vw;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.download-name {
  width: 31.5rem;
  height: 4rem;
  font-size: 1.8rem;
  line-height: 4.29rem;
}
.download-des {
  width: 40rem;
  height: 6rem;
  font-size: 3.2rem;
  line-height: 6rem;
}
.download {
  cursor: pointer;
  width: 10.71rem;
  height: 2.86rem;
  line-height: 2.86rem;
  text-align: center;
  margin-top: 0.5rem;
  background: #ffffff;
  border-radius: 0.29rem;
  font-size: 1.43rem;
  font-weight: 500;
  color: #4ea2fc;
}
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 14rem;
    background-color:white;
    color: #333333;
    text-align: center;
    border-radius: 6px;
    padding: 1.5rem 1rem;
    /* 定位 */
    position: absolute;
    z-index: 1;
    top: 110%;
    left: 50%;
    margin-left: -8rem;
    font-size: 1.3rem;
    box-shadow: 0 0 10px #b9b9b9;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
.problems {
  margin-top: 4.29rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.problem-item {
  text-align: left;
  width: 28.57rem;
  height: 11.64rem;
}
.problem-item1 {
  background: url(../assets/pro1.png);
}
.problem-item2 {
  background: url(../assets/pro2.png);
}
.problem-item3 {
  background: url(../assets/pro3.png);
}
.problem-text {
  margin: 3.07rem 0rem 0rem 2.5rem;
  width: 10.71rem;
  height: 4.71rem;
  font-size: 1.71rem;
  line-height: 2.36rem;
}
.line-text {
  height: 4rem;
  font-size: 2.86rem;
  color: #5c4949;
  line-height: 4rem;
  margin-bottom: 2.5rem;
}
.product-area {
  text-align: center;
  padding: 5.71rem;
}
.product-title {
  margin: 0 auto 4.29rem auto;
  background:#dae6fb;
  width: 12.14rem;
  height: 2.86rem;
  font-size: 2.29rem;
  color: #333333;
  line-height: 2.86rem;
  background: linear-gradient(to bottom, #00000000 0%,#00000000 50%, #E1EFFF 51%, #E1EFFF 100%);
}
.video {
  margin-bottom: 6.86rem;
}
.product-main {
  padding: 7.5rem 0rem;
  text-align: left;
  display: flex;
  justify-content: center;
}
.product-text {
  margin: 0rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.title-area {
  height: 3.21rem;
  font-size: 2.29rem;
  color: #333333;
  line-height: 3.21rem;
  margin-bottom: 0.71rem;
}
.des-area {
  width: 35rem;
  height: 6rem;
  font-size: 1.43rem;
  font-weight: 400;
  color: #333333;
  line-height: 2rem;
}
.protect {
  background: #24284308;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.protect-des1 {
  line-height: 2rem;
  font-size: 1.43rem
}
.protect-des2 {
  line-height: 2rem;
  color: #999999;
  font-size: 1.43rem;
}
.buy-area {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 13rem 0 5.36rem;
}
.price {
  height: 4.64rem;
  font-size: 3.86rem;
  color: #F44C4C;
  line-height: 4.64rem;
}
.base {
  margin: 0.71rem 0rem;
  height: 4rem;
  font-size: 2.86rem;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #333333;
  line-height: 4rem;
}
.license {
  text-align: center;
  width: 14.86rem;
  height: 2.71rem;
  background: #F8873C;
  border-radius: 0.29rem;
  font-size: 1.43rem;
  color: #FFFFFF;
  line-height: 2.71rem;
  margin: 0.71rem 0rem;
}
.buy-info {
  margin: 2rem 0rem;
  display: flex;
  flex-direction: column;
}
.buy-info-title {
  margin-bottom:0.5rem;
  height: 2.36rem;
  font-size: 1.71rem;
  color: #999999;
  line-height: 2.36rem;
}
.buy-info-text {
  margin: 0.5rem 0rem;
  display: flex;
  align-items: center;
  font-size: 1.71rem;
  color: #333333;
  line-height: 2.36rem;
}
.divide {
  width: 0.14rem;
  height: 1.43rem;
  background: #D8D8D8;
  margin: 0rem 2.86rem;
}
.buy-button {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1.71rem;
  width: 38.57rem;
  line-height: 5.71rem;
  height: 5.71rem;
  background: #4EA2FC;
  border-radius: 0.57rem;
}
.buy-inner {
  height: 2.57rem;
  font-size: 1.79rem;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #FFFFFF;
}
a {
  text-decoration:none;
}
</style>
